import React, { useEffect, useRef } from 'react'
import Layout from '../components/Layout'
import Page from '../components/Page'

const FeedbackForm = () => {
  const wraps = useRef(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script')
      script.dataset.skipMoving = true
      script.dataset.b24Form = 'inline/3/wd0x1o'
      script.innerHTML = `
      (function(w,d,u){
        var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
      })(window,document,'https://cdn.bitrix24.ua/b15182659/crm/form/loader_3.js');
      `
      wraps.current.appendChild(script)
    }
  })

  return (
    <Layout>
      <Page>
        <div ref={wraps} id="wrap" />
      </Page>
    </Layout>
  )
}

export default FeedbackForm
